import styled from "styled-components";
import { FlexSpan } from "../Grid";
export const MenuItemContainer = styled(FlexSpan) `
  cursor: pointer;
  border-radius: 0.75rem;
  font-size: 1rem;
  font-style: normal;
  padding: 0.75rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.011em;
  background-color: ${({ active }) => (active ? "#dde0ff" : "#f6f5f8")};
  color: ${({ active }) => (active ? "#5732d3" : "#7b7997")};
  opacity: ${({ disabled }) => disabled && 0.4};

  &:not(:last-of-type) {
    margin-bottom: 0.25rem;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &:not([disabled]) {
    &:hover {
      background-color: #dde0ff;
      color: #5732d3;
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: #dde0ff;
      color: #5732d3;
    }
  }
`;
