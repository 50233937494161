import styled from "styled-components";
import { FlexSpan } from "../../ui";
export const StyledCardContainer = styled(FlexSpan) `
  width: 20.3125rem;
  min-height: 18rem;
  background-color: #f6f5f8;
  border-radius: 1rem;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #8275ad;

  @media only screen and (max-width: 800px) {
    & {
      width: calc(50% - 1rem);
    }
  }

  @media only screen and (max-width: 580px) {
    & {
      width: 100%;
    }
  }
`;
export const SiteDescription = styled.span `
  display: -webkit-box;
  -webkit-line-clamp: ${({ isExpanded }) => (isExpanded ? -1 : 2)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
export const HoverBlock = styled(FlexSpan) `
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;
export const FloatTools = styled.div `
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 1.5rem);
  top: 0.75rem;
  left: 0.75rem;
  z-index: 1;
`;
export const Preview = styled.div `
  width: 100%;
  height: 160px;
  background: ${({ imageSrc }) => `url("${imageSrc}") center no-repeat no-repeat`};
  background-size: cover;
  border-radius: 0.75rem;
  cursor: pointer;
`;
