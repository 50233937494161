import styled from "styled-components";
import { margin, opacity } from "styled-system";
export const DividerContainer = styled.div `
  display: ${({ vertical }) => (vertical ? "inline-block" : "block")};
  width: ${({ vertical }) => (vertical ? "0.0625rem" : "100%")};
  height: ${({ vertical, height }) => (vertical ? height ?? "0.55lh" : "0.0625rem")};
  background-color: ${({ color }) => color ?? "#E1E1E1"};
  ${margin}
  ${opacity}
`;
