import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, setUser } from "../../slices/app";
export const useUser = () => {
    const dispatch = useDispatch();
    const value = useSelector(getUser);
    const update = useCallback((updatedValue) => {
        dispatch(setUser(updatedValue));
    }, [dispatch]);
    return [value, update];
};
