import styled from "styled-components";
export const GeocoderControlWrapper = styled.div `
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 20px;
  top: 20px;
  width: 400px;
  z-index: 6;

  @media only screen and (max-width: 768px) {
    & {
      top: 8px;
      left: 8px;
      width: calc(100% - 16px);
      box-sizing: border-box;
    }
  }
`;
export const GeocoderControlContainer = styled.div `
  width: 100%;
  height: 44px;
  background: #ffffff;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.24);
  border-radius: 12px;
  padding: 6px;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    & {
      height: 40px;
    }
  }
`;
export const GeocoderControlInput = styled.input `
  width: calc(100% - 30px);
  height: 100%;
  border: 0;
  outline: none;
  padding: 14px;
  margin-right: 30px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.011em;
  color: #5732d3;

  &::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.011em;
    color: #7b7997;
  }
`;
export const GeocoderControlClear = styled.div `
  display: flex;
  position: absolute;
  top: 15px;
  right: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    top: 13px;
  }
`;
export const GeocoderControlSuggestions = styled.ul `
  width: 100%;
  list-style-type: none;
  background: #ffffff;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.24);
  border-radius: 0.75rem;
  padding: 0.25rem;
  box-sizing: border-box;
  margin-top: 6px;
`;
export const GeocoderControlSuggestion = styled.li `
  background: #f6f5f8;
  border-radius: 12px;
  padding: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #7b7997;

  @media (hover: hover) {
    &:hover {
      background-color: #dde0ff;
      color: #5732d3;
    }
  }

  &.active {
    background-color: #dde0ff;
    color: #5732d3;
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }
`;
export const SearchCenter = styled.div `
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 36px;
    height: 2px;
    background-color: #5732d3;
    border-radius: 2px;
    top: -6px;
    box-shadow: 0 0 2px 0 #ccc;
  }

  &:before {
    left: -23px;
  }

  &:after {
    transform: rotate(-90deg);
    right: -13px;
  }
`;
