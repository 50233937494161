import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthForm } from "../../components/AuthForm";
import { Button, Checkbox, Flex, FlexSpan, Icon, PasswordInput, Select, TextInput } from "../../ui";
import { STORAGE_TOKEN } from "../../constants/common";
import { VALID_EMAIL_REGEX } from "../../constants/regex";
import { LoginPageUrl, MainPageUrl } from "../../constants/routes";
import { useIndustries } from "../../hooks";
import { api } from "../../api";
export const SignUpPage = () => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [industry, setIndustry] = useState();
    const [accepted, setAccepted] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordConfirmVisible, setIsPasswordConfirmVisible] = useState(false);
    const storageToken = window.localStorage.getItem(STORAGE_TOKEN);
    const isFirstRender = useRef(true);
    const [industries] = useIndustries();
    const [success, setSuccess] = useState(false);
    const disabled = useMemo(() => !name ||
        name.length < 2 ||
        !email ||
        !password ||
        !confirmPassword ||
        !industry ||
        nameError ||
        emailError ||
        passwordError ||
        confirmPasswordError ||
        !accepted, [
        accepted,
        confirmPassword,
        confirmPasswordError,
        email,
        emailError,
        industry,
        name,
        nameError,
        password,
        passwordError,
    ]);
    const onSubmit = useCallback(() => {
        if (disabled) {
            return;
        }
        isFirstRender.current = false;
        api.auth
            .createSignUp({
            name,
            email,
            password,
            industry_id: industry,
        })
            .then(() => {
            setSuccess(true);
        });
    }, [disabled, email, industry, name, password]);
    useEffect(() => {
        if (storageToken && isFirstRender.current) {
            navigate(MainPageUrl.path);
        }
    }, [storageToken]); // eslint-disable-line
    if (success) {
        return (_jsx(AuthForm, { icon: "done", title: "Account successfully created", subtitle: "We are glad that you joined us!", children: _jsx(Button, { width: "100%", height: "2.75rem", onClick: () => navigate(LoginPageUrl.path), children: "Log In" }) }));
    }
    return (_jsx(AuthForm, { title: "Create account", subtitle: "Create strategies and analyze city sites", children: _jsxs(Flex, { column: true, gap: "0.5rem", width: "100%", children: [_jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(TextInput, { autoComplete: "off", placeholder: "Your full name", value: name, onChange: value => {
                                        setNameError(false);
                                        setName(value);
                                    }, onEnter: onSubmit, onBlur: () => setNameError(name.length > 0 && name.length < 2), error: nameError }) }), nameError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: "Two characters minimum" }))] }) }), _jsx(FlexSpan, { width: "100%", children: _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(TextInput, { autoComplete: "off", placeholder: "Email", value: email, onChange: value => {
                                            setEmailError(false);
                                            setEmail(value);
                                        }, onEnter: onSubmit, onBlur: () => setEmailError(email.length > 0 && !email.match(VALID_EMAIL_REGEX)), error: emailError }) }), emailError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: "Invalid email format" }))] }) }) }), _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(PasswordInput, { autoComplete: "off", placeholder: "Password", value: password, onChange: value => {
                                        setPasswordError(false);
                                        setConfirmPasswordError(false);
                                        setPassword(value);
                                    }, onEnter: onSubmit, onBlur: () => {
                                        setPasswordError(password.length > 0 && password.length < 6);
                                        setConfirmPasswordError(password.length > 0 && confirmPassword.length > 0 && confirmPassword !== password);
                                    }, error: passwordError, isPasswordVisible: isPasswordVisible, suffix: password && (_jsx(FlexSpan, { display: "flex", style: { cursor: "pointer" }, onClick: () => setIsPasswordVisible(!isPasswordVisible), children: _jsx(Icon, { icon: isPasswordVisible ? "eye-closed" : "eye", color: "#8275AD", hoverColor: "#5732d3" }) })) }) }), passwordError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: "Six characters minimum" }))] }) }), _jsx(FlexSpan, { width: "100%", children: _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(PasswordInput, { autoComplete: "off", placeholder: "Confirm password", value: confirmPassword, onChange: value => {
                                            setConfirmPasswordError(false);
                                            setConfirmPassword(value);
                                        }, onEnter: onSubmit, onBlur: () => setConfirmPasswordError(password.length > 0 && confirmPassword.length > 0 && confirmPassword !== password), error: confirmPasswordError, isPasswordVisible: isPasswordConfirmVisible, suffix: confirmPassword && (_jsx(FlexSpan, { display: "flex", style: { cursor: "pointer" }, onClick: () => setIsPasswordConfirmVisible(!isPasswordConfirmVisible), children: _jsx(Icon, { icon: isPasswordConfirmVisible ? "eye-closed" : "eye", color: "#8275AD", hoverColor: "#5732d3" }) })) }) }), confirmPasswordError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: "Passwords do not match" }))] }) }) }), _jsx(FlexSpan, { width: "100%", children: _jsx(Select, { placeholder: "Industry/Sector", options: industries?.map(item => ({ value: item.id, label: item.title })) ?? [], onChange: option => setIndustry(option.value) }) }), _jsx(FlexSpan, { width: "100%", mt: "1rem", children: _jsx(Checkbox, { label: _jsxs(_Fragment, { children: ["I agree and accept", " ", _jsx("a", { target: "_blank", onClick: e => e.stopPropagation(), href: "https://onecity.dev/terms", rel: "noreferrer", children: "Terms and Conditions" })] }), checked: accepted, onChange: () => setAccepted(!accepted) }) }), _jsx(FlexSpan, { mt: "2rem", children: _jsx(Button, { width: "100%", height: "2.75rem", onClick: onSubmit, disabled: disabled, children: "Sign Up" }) }), _jsxs(FlexSpan, { mt: "2rem", width: "100%", textAlign: "center", fontWeight: 500, fontSize: "16px", lineHeight: "20px", color: "#8275AD", children: ["Already have an account? ", _jsx(Link, { to: LoginPageUrl.path, children: "Log In" })] })] }) }));
};
