import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Flex, FlexSpan, Icon, Modal } from "../../../ui";
import { DIALOGS } from "../../../constants/dialogs";
import { STORAGE_TOKEN } from "../../../constants/common";
import { LoginPageUrl } from "../../../constants/routes";
import { useDialog, useDialogReset, useIndustries, useSites, useToken, useUser } from "../../../hooks";
import { api } from "../../../api";
export const AccountDialog = () => {
    const navigate = useNavigate();
    const openDialog = useDialog();
    const closeDialog = useDialogReset();
    const [user, setUser] = useUser();
    const [, setToken] = useToken();
    const [, setSites] = useSites();
    const [industries] = useIndustries();
    const onLogOut = useCallback(async () => {
        await api.auth.createLogout().then(() => {
            closeDialog();
            setUser(null);
            setToken(undefined);
            setSites(undefined);
            window.localStorage.removeItem(STORAGE_TOKEN);
            navigate(LoginPageUrl.path);
        });
    }, [closeDialog, navigate, setSites, setToken, setUser]);
    if (!user) {
        return null;
    }
    return (_jsx(Modal, { content: _jsxs(Flex, { column: true, gap: "2rem", children: [_jsx(FlexSpan, { fontWeight: 500, fontSize: "24px", lineHeight: "29px", children: "Account" }), _jsx(FlexSpan, { fontWeight: 500, fontSize: "16px", lineHeight: "20px", children: _jsxs(Flex, { column: true, gap: "0.75rem", children: [_jsx(FlexSpan, { children: _jsxs(Flex, { justifyContent: "space-between", color: "#8275AD", children: [_jsx(FlexSpan, { children: "Name" }), _jsx(FlexSpan, { style: { textDecoration: "underline", cursor: "pointer" }, onClick: () => openDialog(DIALOGS.CHANGE_NAME_DIALOG), children: "Change name" })] }) }), _jsx(FlexSpan, { children: user.name })] }) }), _jsx(FlexSpan, { fontWeight: 500, fontSize: "16px", lineHeight: "20px", children: _jsxs(Flex, { column: true, gap: "0.75rem", children: [_jsx(FlexSpan, { children: _jsxs(Flex, { justifyContent: "space-between", color: "#8275AD", children: [_jsx(FlexSpan, { children: "Email" }), _jsx(FlexSpan, { style: { textDecoration: "underline", cursor: "pointer" }, onClick: () => openDialog(DIALOGS.CHANGE_EMAIL_DIALOG), children: "Change email" })] }) }), _jsx(FlexSpan, { children: user.email })] }) }), _jsx(FlexSpan, { fontWeight: 500, fontSize: "16px", lineHeight: "20px", children: _jsxs(Flex, { column: true, gap: "0.75rem", children: [_jsx(FlexSpan, { children: _jsxs(Flex, { justifyContent: "space-between", color: "#8275AD", children: [_jsx(FlexSpan, { children: "Industry/Sector" }), _jsx(FlexSpan, { style: { textDecoration: "underline", cursor: "pointer" }, onClick: () => openDialog(DIALOGS.CHANGE_INDUSTRY_DIALOG), children: "Change industry" })] }) }), _jsx(FlexSpan, { children: industries.find(item => item.id === user.industry_id)?.title })] }) }), _jsx(FlexSpan, { children: _jsxs(Button, { secondary: true, height: "2.75rem", onClick: onLogOut, children: [_jsx(Icon, { icon: "arrow-left", mr: "0.5rem" }), "Log Out"] }) })] }), onClose: closeDialog }));
};
