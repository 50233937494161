import styled from "styled-components";
import { Flex } from "../../ui";
export const AppHeaderContainer = styled(Flex) `
  justify-content: space-between;
  align-items: center;
  height: ${({ isFixed }) => (isFixed ? "3.5rem" : "auto")};
  min-height: ${({ isFixed }) => (isFixed ? "3.5rem" : "auto")};
  box-sizing: border-box;
  padding: ${({ isFixed }) => (isFixed ? "0 0.75rem" : 0)};
`;
export const AppHeaderUserIcon = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  background: linear-gradient(180deg, #ffcae2 0%, #5732d3 100%);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
`;
