import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useCallback } from "react";
import { ModalContainer, ModalWrap, Overflow, Container, TitleContainer, Content, Footer } from "./styled";
import { FlexSpan } from "../Grid";
import { IconButton } from "../IconButton";
import { useCloseOnEscape } from "../../hooks";
export const ModalContext = createContext({});
export const Modal = ({ width, maxWidth, height, maxHeight, onClose, maskClosable = true, hideMask, zIndex, isFixed, title, closable = true, paddingTitle, paddingContent, content, footer, footerJustify, keyboard = true, contextValue, titleStyle, footerStyle, }) => {
    const onCloseDialog = useCallback(() => {
        if (closable) {
            onClose?.();
        }
    }, [closable, onClose]);
    useCloseOnEscape(keyboard ? onCloseDialog : undefined);
    return (_jsx(ModalContext.Provider, { value: contextValue, children: _jsxs(ModalContainer, { zIndex: zIndex, isFixed: isFixed, children: [!hideMask && _jsx(Overflow, { zIndex: zIndex, onClick: () => maskClosable && onCloseDialog() }), _jsx(ModalWrap, { width: width, maxWidth: maxWidth, height: height, maxHeight: maxHeight, children: _jsxs(Container, { children: [_jsxs(TitleContainer, { padding: paddingTitle, style: titleStyle, children: [_jsx(FlexSpan, { fontWeight: 500, fontSize: "24px", lineHeight: "29px", children: title }), closable && _jsx(IconButton, { icon: "close", onClick: onCloseDialog })] }), _jsx(Content, { padding: paddingContent, children: content }), footer && (_jsx(Footer, { footerJustify: footerJustify, style: footerStyle, children: footer }))] }) })] }) }));
};
