import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { LoginDialog } from "./LoginDialog";
import { AddSiteNoteDialog } from "./AddSiteNoteDialog";
import { AccountDialog } from "./AccountDialog";
import { ChangeNameDialog } from "./ChangeNameDialog";
import { ChangeEmailDialog } from "./ChangeEmailDialog";
import { ChangeIndystryDialog } from "./ChangeIndystryDialog";
import { DIALOGS } from "../../constants/dialogs";
import { getCurrentDialog } from "../../slices/dialog";
const DIALOGS_MAP = {
    [DIALOGS.LOGIN_DIALOG]: LoginDialog,
    [DIALOGS.ADD_SITE_NOTE_DIALOG]: AddSiteNoteDialog,
    [DIALOGS.ACCOUNT_DIALOG]: AccountDialog,
    [DIALOGS.CHANGE_NAME_DIALOG]: ChangeNameDialog,
    [DIALOGS.CHANGE_EMAIL_DIALOG]: ChangeEmailDialog,
    [DIALOGS.CHANGE_INDUSTRY_DIALOG]: ChangeIndystryDialog,
};
export const Dialogs = () => {
    const currentDialog = useSelector(getCurrentDialog);
    const Dialog = DIALOGS_MAP[currentDialog];
    return Dialog ? _jsx(Dialog, {}) : null;
};
