import styled from "styled-components";
import { background, border, color, flexbox, fontSize, fontWeight, fontStyle, space, layout, textAlign, position, lineHeight, } from "styled-system";
const setWidth = (width) => typeof width === "number" ? `${width}px` : typeof width === "undefined" ? "auto" : width;
export const Flex = styled.div `
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  width: 100%;
  gap: ${({ gap }) => gap};
  box-sizing: border-box;
  ${background}
  ${border}
  ${color}
  ${flexbox}
  ${fontSize}
  ${fontWeight}
  ${fontStyle}
  ${space}
  ${layout}
  ${textAlign}
  ${position}
  ${lineHeight}
`;
export const FlexSpan = styled.span `
  position: relative;
  box-sizing: border-box;
  width: ${({ width }) => setWidth(width)};
  white-space: ${({ whiteSpace }) => whiteSpace};
  ${background}
  ${border}
  ${color}
  ${flexbox}
  ${fontSize}
  ${fontWeight}
  ${fontStyle}
  ${space}
  ${layout}
  ${textAlign}
  ${position}
  ${lineHeight}
`;
