import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Flex, FlexSpan, Modal, TextInput } from "../../../ui";
import { DIALOGS } from "../../../constants/dialogs";
import { useDialog, useUser } from "../../../hooks";
import { api } from "../../../api";
export const ChangeNameDialog = () => {
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);
    const openDialog = useDialog();
    const [user, setUser] = useUser();
    const disabled = useMemo(() => !name || name.length < 2, [name]);
    const onSave = useCallback(() => {
        if (disabled) {
            return;
        }
        api.users
            .updateMe({ name })
            .then(setUser)
            .finally(() => openDialog(DIALOGS.ACCOUNT_DIALOG));
    }, [disabled, name, openDialog, setUser]);
    useEffect(() => {
        if (user) {
            setName(user.name);
        }
    }, [user]);
    if (!user) {
        return null;
    }
    return (_jsx(Modal, { content: _jsxs(Flex, { column: true, gap: "2rem", children: [_jsx(FlexSpan, { fontWeight: 500, fontSize: "24px", lineHeight: "29px", children: "Change name" }), _jsx(FlexSpan, { children: _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(TextInput, { placeholder: "Your full name", value: name, onChange: value => {
                                            setNameError(false);
                                            setName(value);
                                        }, onBlur: () => setNameError(name.length > 0 && name.length < 2), error: nameError }) }), nameError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: "Two characters minimum" }))] }) }) })] }), footer: _jsxs(Flex, { width: "auto", gap: "0.75rem", children: [_jsx(FlexSpan, { children: _jsx(Button, { secondary: true, height: "2.5rem", pl: "2rem", pr: "2rem", onClick: () => openDialog(DIALOGS.ACCOUNT_DIALOG), children: "Cancel" }) }), _jsx(FlexSpan, { children: _jsx(Button, { height: "2.5rem", pl: "2rem", pr: "2rem", onClick: onSave, disabled: disabled, children: "Save" }) })] }), onClose: () => openDialog(DIALOGS.ACCOUNT_DIALOG) }));
};
