import { jsx as _jsx } from "react/jsx-runtime";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import WebFont from "webfontloader";
import { Pages } from "./pages";
import store from "./store";
WebFont.load({ google: { families: ["Inter:100,200,300,400,500,600,700"] } });
export const Root = () => {
    return (_jsx(Provider, { store: store, children: _jsx(BrowserRouter, { basename: process.env.APP_PATH, children: _jsx(Pages, {}) }) }));
};
const root = createRoot(document.getElementById("root"));
root.render(_jsx(Root, {}));
