import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIndustries, setIndustries } from "../../slices/app";
export const useIndustries = () => {
    const dispatch = useDispatch();
    const value = useSelector(getIndustries);
    const update = useCallback((updatedValue) => {
        dispatch(setIndustries(updatedValue));
    }, [dispatch]);
    return [value, update];
};
