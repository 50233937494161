import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FloatTools, HoverBlock, Preview, SiteDescription, StyledCardContainer } from "./styled";
import { Flex, FlexSpan, Icon, IconButton, Menu, Popover } from "../../ui";
import { DIALOGS } from "../../constants/dialogs";
import { useDialog, useSites } from "../../hooks";
import { formatNumber } from "../../utils/format";
import { getFeatureOnPoint } from "../../utils/getFeatureOnPoint";
import { copyToClipboard } from "../../utils/copyToClipboard";
import { isTouch } from "../../utils/isTouch";
import { api } from "../../api";
export const SiteCard = ({ site }) => {
    const [isHover, setIsHover] = useState(false);
    const [, setSites] = useSites();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [linkPoint, setLinkPoint] = useState();
    const [linkCopied, setLinkCopied] = useState(false);
    const openDialog = useDialog();
    const parsedFeature = useMemo(() => {
        if (!site) {
            return;
        }
        return JSON.parse(site.feature);
    }, [site]);
    useEffect(() => {
        setLinkPoint(getFeatureOnPoint(parsedFeature).geometry.coordinates.join(","));
    }, [parsedFeature]);
    const featureGeoJson = useMemo(() => {
        if (!parsedFeature) {
            return;
        }
        return {
            type: parsedFeature.type,
            properties: {
                fill: "#5732D3",
                "stroke-width": 0,
            },
            geometry: parsedFeature.geometry,
        };
    }, [parsedFeature]);
    const onToggleLike = useCallback(() => {
        api.userSites
            .updateUserSite(site.id, {
            is_favorite: !site.is_favorite,
        })
            .then(setSites);
    }, [setSites, site.id, site.is_favorite]);
    const onDelete = useCallback(() => {
        api.userSites.deleteUserSite(site.id).then(setSites);
    }, [setSites, site.id]);
    useEffect(() => {
        setIsMenuOpen(false);
    }, [isHover]);
    useEffect(() => {
        setLinkCopied(false);
    }, [isMenuOpen]);
    if (!site) {
        return null;
    }
    return (_jsx(StyledCardContainer, { onMouseOver: () => setIsHover(true), onMouseLeave: () => setIsHover(false), children: _jsxs(Flex, { column: true, height: "100%", children: [_jsxs(FlexSpan, { height: "10rem", minHeight: "10rem", children: [_jsxs(FloatTools, { children: [_jsx(FlexSpan, { children: site.is_favorite ? (_jsx(IconButton, { icon: "like", active: true, onClick: onToggleLike })) : (_jsx(HoverBlock, { isVisible: isHover || isTouch(), children: _jsx(IconButton, { icon: "like", onClick: onToggleLike }) })) }), _jsx(FlexSpan, { children: _jsx(HoverBlock, { isVisible: isHover || isTouch(), children: (isHover || isTouch()) && (_jsx(Popover, { placement: "bottom-right", anchor: _jsx(IconButton, { icon: "menu-vertical", active: isMenuOpen }), onOpen: () => setIsMenuOpen(true), onClose: () => setIsMenuOpen(false), children: _jsx(Menu, { width: "13.25rem", onPick: item => {
                                                    if (item.value === 1) {
                                                        copyToClipboard(`${window.location.origin}/?c=${linkPoint}`, true).then(() => {
                                                            setLinkCopied(true);
                                                        });
                                                    }
                                                    if (item.value === 2) {
                                                        onDelete();
                                                    }
                                                }, items: [
                                                    {
                                                        value: 1,
                                                        label: linkCopied ? "Link copied!" : "Copy link",
                                                        prefix: _jsx(Icon, { icon: linkCopied ? "confirm" : "link" }),
                                                    },
                                                    { value: 2, label: "Delete from recent", prefix: _jsx(Icon, { icon: "trash" }) },
                                                ] }) })) }) })] }), _jsx(FlexSpan, { onClick: e => {
                                e.stopPropagation();
                                window.location.href = `${window.location.origin}/?c=${linkPoint}`;
                            }, children: _jsx(Preview, { imageSrc: `https://api.mapbox.com/styles/v1/mapbox/light-v11/static/geojson(${encodeURI(JSON.stringify(featureGeoJson)).replace(/#/, "%23")})/auto/800x160@2x?attribution=false&logo=false&padding=35,200,35,200&access_token=${process.env.ACCESS_TOKEN}` }) })] }), _jsx(FlexSpan, { p: "0.75rem", height: "100%", children: _jsxs(Flex, { column: true, justifyContent: "space-between", height: "100%", children: [_jsx(FlexSpan, { flexGrow: 1, children: _jsxs(Flex, { column: true, gap: "0.25rem", children: [_jsxs(FlexSpan, { fontWeight: 500, fontSize: "16px", lineHeight: "20px", color: "initial", children: [formatNumber(parsedFeature.properties.LEGAL_AREA), " m", _jsx("sup", { children: "2" })] }), _jsx(FlexSpan, { children: parsedFeature.properties.SG_ID }), _jsxs(FlexSpan, { children: [parsedFeature.properties.ZONE_DESC, " \u2022 ", parsedFeature.properties.owner_t] }), site.note && (_jsx(FlexSpan, { children: _jsx(SiteDescription, { isExpanded: isExpanded, children: site.note }) }))] }) }), _jsx(FlexSpan, { children: _jsxs(Flex, { alignItems: "center", justifyContent: "space-between", mt: "0.25rem", children: [_jsx(FlexSpan, { children: _jsx(HoverBlock, { isVisible: isHover || isTouch(), children: _jsx(FlexSpan, { style: { textDecoration: "underline", cursor: "pointer" }, onClick: () => {
                                                        openDialog(DIALOGS.ADD_SITE_NOTE_DIALOG, {
                                                            site,
                                                        });
                                                    }, children: site.note ? "Edit note" : "Add note" }) }) }), Boolean(site.note?.length) && (_jsx(FlexSpan, { display: "flex", style: { cursor: "pointer" }, children: _jsx(Icon, { icon: isExpanded ? "reduce" : "expand", size: 12, onClick: () => setIsExpanded(!isExpanded) }) }))] }) })] }) })] }) }));
};
