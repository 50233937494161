export class Users {
    http;
    constructor(http) {
        this.http = http;
    }
    getMe() {
        return this.http.get(`/api/users/me`).json();
    }
    updateMe(data) {
        return this.http.patch(`/api/users/me`, data).json();
    }
}
