import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Button, Flex, FlexSpan, Modal, Textarea } from "../../../ui";
import { useDialogProps, useDialogReset, useSites } from "../../../hooks";
import { api } from "../../../api";
export const AddSiteNoteDialog = () => {
    const [{ site }] = useDialogProps();
    const [note, setNote] = useState("");
    const closeDialog = useDialogReset();
    const [, setSites] = useSites();
    const onSave = useCallback(() => {
        api.userSites.updateUserSite(site.id, { note }).then(setSites).finally(closeDialog);
    }, [closeDialog, note, setSites, site.id]);
    useEffect(() => {
        setNote(site?.note ?? "");
    }, [site]);
    if (!site) {
        return null;
    }
    return (_jsx(Modal, { content: _jsxs(Flex, { column: true, gap: "2rem", children: [_jsx(FlexSpan, { fontWeight: 500, fontSize: "24px", lineHeight: "29px", children: "Add note" }), _jsx(FlexSpan, { children: _jsx(Textarea, { placeholder: "Enter your note", value: note, maxLength: 200, height: "7.5rem", onChange: setNote }) })] }), footer: _jsxs(Flex, { width: "auto", gap: "0.75rem", children: [_jsx(FlexSpan, { children: _jsx(Button, { secondary: true, height: "2.5rem", pl: "2rem", pr: "2rem", onClick: closeDialog, children: "Cancel" }) }), _jsx(FlexSpan, { children: _jsx(Button, { height: "2.5rem", pl: "2rem", pr: "2rem", onClick: onSave, children: "Save" }) })] }), onClose: closeDialog }));
};
