import { HttpClient } from './HttpClient';
import { Auth } from "./Auth";
import { Email } from "./Email";
import { Features } from "./Features";
import { Industries } from "./Industries";
import { Reports } from "./Reports";
import { Users } from "./Users";
import { UserSites } from "./UserSites";
export class Api {
    http;
    auth;
    email;
    features;
    industries;
    reports;
    users;
    userSites;
    constructor(url) {
        this.http = new HttpClient(url);
        this.auth = new Auth(this.http);
        this.email = new Email(this.http);
        this.features = new Features(this.http);
        this.industries = new Industries(this.http);
        this.reports = new Reports(this.http);
        this.users = new Users(this.http);
        this.userSites = new UserSites(this.http);
    }
}
