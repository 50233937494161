import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    currentDialog: "",
};
export const dialogSlice = createSlice({
    name: "dialog",
    initialState,
    reducers: {
        setCurrentDialog: (state, action) => {
            state.currentDialog = action.payload;
        },
        setDialogProps: (state, action) => {
            state.dialogProps = action.payload;
        },
        resetDialog: () => initialState,
    },
});
export const { setCurrentDialog, setDialogProps, resetDialog } = dialogSlice.actions;
export * from "./selectors";
export default dialogSlice.reducer;
