import { createSlice } from "@reduxjs/toolkit";
const initialState = {};
export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setIndustries: (state, action) => {
            state.industries = action.payload;
        },
        setSites: (state, action) => {
            state.sites = action.payload;
        },
    },
});
export const { setUser, setToken, setIndustries, setSites } = appSlice.actions;
export * from "./selectors";
export default appSlice.reducer;
