import { useCallback, useEffect, useRef } from "react";
const EXCLUDED_IDS = ["react-select"];
export const useClickOutside = (callback, deps = []) => {
    const ref = useRef(null);
    const handleClick = useCallback(e => {
        if (ref.current && !ref.current.contains(e.target) && !EXCLUDED_IDS.some(item => e.target.id?.includes(item))) {
            callback();
        }
    }, [ref, callback, ...deps]);
    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [handleClick]);
    return ref;
};
