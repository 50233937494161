import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { LayersButton, LayersControlWrapper, LayersButtonLabel } from "./styled";
import { Icon, Menu, Popover } from "../../ui";
const DEFAULT_OPTION = 2;
export const LayersControl = ({ onSelect }) => {
    const [expanded, setExpanded] = useState(false);
    const [selectedOption, setSelectedOption] = useState(DEFAULT_OPTION);
    const onItemSelect = useCallback((value) => {
        setExpanded(false);
        setSelectedOption(value);
        onSelect && onSelect(value);
    }, [onSelect]);
    useEffect(() => {
        onSelect && onSelect(DEFAULT_OPTION);
    }, []); // eslint-disable-line
    return (_jsx(LayersControlWrapper, { children: _jsx(Popover, { placement: "top-right", offsetY: 8, isOpen: expanded, onOpen: () => setExpanded(true), onClose: () => setExpanded(false), anchor: _jsxs(LayersButton, { height: "36px", active: expanded, onClick: () => setExpanded(!expanded), children: [_jsx(Icon, { icon: "layers", size: 18 }), _jsx(LayersButtonLabel, { children: "Layers" })] }), children: _jsx(Menu, { width: "11.25rem", onPick: item => onItemSelect(item.value), items: [
                    { value: 1, label: "Satellite", active: selectedOption === 1 },
                    { value: 2, label: "Schematic", active: selectedOption === 2 },
                ] }) }) }));
};
