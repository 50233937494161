import styled from "styled-components";
import { Flex, FlexSpan } from "../../ui";
import loginBackground from "../../assets/images/login-background.png";
export const AuthFormWrapper = styled(Flex) `
  height: var(--app-height);
`;
export const AuthFormContainer = styled(FlexSpan) `
  width: 33.75rem;
  background: #ffffff;
  box-sizing: border-box;
  padding: 2.5rem 3.5rem 3rem;

  @media only screen and (max-width: 430px) {
    & {
      width: 100%;
      padding: 2.5rem;
    }
  }
`;
export const AuthFormBackground = styled(FlexSpan) `
  flex-grow: 1;
  background: url(${loginBackground}) no-repeat;
  background-size: cover;
`;
export const AuthFormIconContainer = styled(Flex) `
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.75rem;
  background-color: #dde0ff;
`;
