import styled from "styled-components";
import { space } from "styled-system";
export const Icon = styled.span `
  display: inline-flex;
  transition: 0.1s ease-in-out;
  box-sizing: border-box;
  ${space}

  & > svg {
    pointer-events: none;
  }

  &:hover {
    & > svg {
      color: ${({ hoverColor }) => hoverColor};
    }
  }
`;
