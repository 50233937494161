import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { Button, Flex, FlexSpan } from "../../ui";
import { AppHeaderContainer, AppHeaderUserIcon } from "./styled";
import { DIALOGS } from "../../constants/dialogs";
import { LoginPageUrl, PersonalPageUrl } from "../../constants/routes";
import { useDialog, useUser } from "../../hooks";
import logo from "../../assets/images/logo.png";
export const AppHeader = ({ isFixed }) => {
    const navigate = useNavigate();
    const [user] = useUser();
    const openDialog = useDialog();
    return (_jsxs(AppHeaderContainer, { isFixed: isFixed, children: [_jsx(FlexSpan, { style: { cursor: "pointer" }, onClick: () => {
                    if (user) {
                        navigate(PersonalPageUrl.path);
                        return;
                    }
                    navigate(LoginPageUrl.path);
                }, children: _jsx("img", { src: logo, width: "102px", alt: "OneCity Logo" }) }), _jsx(FlexSpan, { children: user ? (_jsxs(Flex, { gap: "0.5rem", style: { cursor: "pointer" }, onClick: () => openDialog(DIALOGS.ACCOUNT_DIALOG), children: [_jsx(FlexSpan, { children: _jsx(AppHeaderUserIcon, { children: user.name[0].toUpperCase() }) }), _jsx(FlexSpan, { children: _jsxs(Flex, { column: true, children: [_jsx(FlexSpan, { fontWeight: 500, fontSize: "16px", lineHeight: "20px", children: user.name }), _jsx(FlexSpan, { fontWeight: 500, fontSize: "10px", lineHeight: "12px", color: "#8275AD", children: user.email })] }) })] })) : user === null ? (_jsx(Flex, { children: _jsx(FlexSpan, { children: _jsx(Button, { height: "2.75rem", onClick: () => openDialog(DIALOGS.LOGIN_DIALOG), children: "Log In" }) }) })) : null })] }));
};
