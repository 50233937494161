import styled from "styled-components";
export const ResultDialogContainer = styled.div `
  position: absolute;
  top: 70px;
  left: 20px;
  width: 400px;
  background-color: #ffffff;
  padding: 1rem;
  box-sizing: border-box;
  z-index: 5;
  border-radius: 1rem;
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);

  @media only screen and (max-width: 768px) {
    & {
      top: 54px;
      left: 8px;
      width: calc(100% - 16px);
      box-sizing: border-box;
      padding-top: 20px;
    }
  }

  h2 {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.011em;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.011em;
    margin: 8px 0 0;
  }
`;
export const LoaderContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.011em;
  color: #7b7997;
`;
export const NoSites = styled.div `
  background: #7b7997;
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.011em;
  color: #e1e1e1;
  padding: 12px;

  h3 {
    margin: 0 0 4px 0;
    padding: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }
`;
