export class UserSites {
    http;
    constructor(http) {
        this.http = http;
    }
    getUserSites() {
        return this.http.get(`/api/user_sites`).json();
    }
    createUserSite(data) {
        return this.http.post(`/api/user_sites/add`, data).json();
    }
    updateUserSite(id, data) {
        return this.http.patch(`/api/user_sites/${id}`, data).json();
    }
    deleteUserSite(id) {
        return this.http.delete(`/api/user_sites/${id}`).json();
    }
}
