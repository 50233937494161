const makeNotAllowedError = () => {
    throw new Error("Action is not allowed");
};
const copyClipboardSafe = async (text) => {
    if (!navigator.clipboard) {
        makeNotAllowedError();
    }
    return navigator.clipboard.writeText(text);
};
const copyTextUnsafe = async (text) => {
    const span = document.createElement("span");
    span.textContent = text;
    span.style.whiteSpace = "pre";
    span.style.userSelect = "auto";
    span.style.userSelect = "all";
    document.body.appendChild(span);
    const selection = window.getSelection();
    const range = window.document.createRange();
    selection?.removeAllRanges();
    range.selectNode(span);
    selection?.addRange(range);
    try {
        const ok = window.document.execCommand("copy");
        if (!ok) {
            makeNotAllowedError();
        }
    }
    finally {
        selection?.removeAllRanges();
        window.document.body.removeChild(span);
    }
};
export const copyToClipboard = async (text, allowUnsafeAction = false) => {
    if (text) {
        try {
            await copyClipboardSafe(text);
        }
        catch (err) {
            try {
                // eslint-disable-next-line max-depth
                if (allowUnsafeAction) {
                    await copyTextUnsafe(text);
                }
            }
            catch (err2) {
                throw err2 || err;
            }
        }
    }
};
