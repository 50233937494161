export class Auth {
    http;
    constructor(http) {
        this.http = http;
    }
    createLogin(data) {
        return this.http.post(`/api/auth/login`, data).json();
    }
    createSignUp(data) {
        return this.http.post(`/api/auth/register`, data).json();
    }
    createLogout() {
        return this.http.post(`/api/auth/logout`).json();
    }
}
