export class Reports {
    http;
    constructor(http) {
        this.http = http;
    }
    downloadReport(id) {
        return this.http.get(`/api/reports/download/${id}`).json();
    }
    createReport(data) {
        return this.http.post(`/api/reports/create`, data).json();
    }
}
