import styled from "styled-components";
export const ModeSwitchOptionLabel = styled.div `
  display: flex;
  align-items: center;
`;
export const ModeSwitchOption = styled.div `
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background-color: ${({ active }) => (active ? "#5732D3" : "#F6F5F8")};
  color: ${({ active }) => (active ? "#ffffff" : "#7B7997")};
  border-radius: 0.75rem;
  height: 40px;
  box-sizing: border-box;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: -0.132px;

  @media (hover: hover) {
    &:hover {
      background-color: #dde0ff;
      color: #5732d3;
    }
  }
`;
export const HidableText = styled.span `
  display: inline-block;
  margin-left: 4px;
`;
export const ModeSwitch = styled.div `
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  padding: 4px;
  background-color: #ffffff;
  border-radius: 16px;
  height: 48px;
  box-sizing: border-box;
  pointer-events: ${({ disabled }) => disabled && "none"};
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.24);
  z-index: 4;

  ${ModeSwitchOption} {
    &:not(:last-child) {
      margin-right: 6px;
    }

    &:hover {
      cursor: ${({ disabled }) => !disabled && "pointer"};
    }
  }

  @media only screen and (max-width: 768px) {
    & {
      width: 206px;
      top: 64px;
      right: calc(50vw - 103px);
      height: 36px;

      ${ModeSwitchOption} {
        height: 28px;
      }

      ${HidableText} {
        display: none;
      }
    }
  }
`;
