import styled from "styled-components";
import { space } from "styled-system";
import { Flex } from "../Grid";
export const setWidth = (width, allowUndefined) => {
    if (typeof width === "number") {
        return `${width}px`;
    }
    return typeof width === "undefined" ? (!allowUndefined ? "29rem" : undefined) : width;
};
export const setHeight = (height, allowUndefined) => {
    if (typeof height === "number") {
        return `${height}px`;
    }
    return typeof height === "undefined" ? (!allowUndefined ? "calc(var(--app-height) - 15rem)" : undefined) : height;
};
export const ModalWrap = styled.div `
  margin: 0;
  background: #ffffff;
  box-shadow: 0 0.25rem 2.75rem 0 rgba(63, 67, 106, 0.3);
  border-radius: 1.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ width }) => setWidth(width)};
  max-width: ${({ maxWidth }) => setWidth(maxWidth, true)};
  min-width: 12.5rem;
  height: ${({ height }) => setHeight(height, true)};
  max-height: ${({ maxHeight }) => setHeight(maxHeight)};
  min-height: 6.25rem;

  @media only screen and (max-width: 430px) {
    top: unset;
    left: unset;
    bottom: 0;
    transform: none;
    border-radius: 1.5rem 1.5rem 0 0;
    width: 100%;
    max-height: calc(var(--app-height) - 4rem);
  }
`;
export const Overflow = styled.div `
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${({ zIndex }) => (typeof zIndex === "number" ? zIndex - 1 : 998)};
  background-color: rgba(0, 0, 0, 0.5);
`;
export const ModalContainer = styled.div `
  ${ModalWrap} {
    position: ${({ isFixed }) => (isFixed ? "fixed" : "absolute")};
    z-index: ${({ zIndex }) => (zIndex !== undefined ? zIndex : 999)};
  }

  ${Overflow} {
    position: ${({ isFixed }) => (isFixed ? "fixed" : "absolute")};
    z-index: ${({ zIndex }) => (zIndex !== undefined ? zIndex - 1 : 999)};
  }
`;
export const Container = styled(Flex) `
  flex-direction: column;
  max-height: inherit;
  padding: 1rem 0 0.5rem;
  box-sizing: border-box;
  height: 100%;
`;
export const TitleContainer = styled(Flex) `
  align-items: baseline;
  justify-content: space-between;
  padding: 1rem 1.5rem 1.5rem 2.25rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
`;
export const Content = styled.div `
  padding: 0 1.75rem 0 2.25rem;
  margin-right: 0.5rem;
  flex-grow: 1;
  overflow: auto;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
  ${space}
`;
export const Footer = styled.div `
  display: flex;
  padding: 1rem 2.25rem 1.5rem;
  align-items: center;
  justify-content: ${({ footerJustify }) => footerJustify || "flex-end"};
`;
